@media print {
  @page {
    margin-bottom: 10mm;
  }
}

div.header-spacer {
  height: 25mm;
}

div.content {
  margin-right: 25mm;
  margin-left: 25mm;
}

div.footer-spacer {
  height: 25mm;
  margin-right: 25mm;
  margin-left: 25mm;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 25mm;
  position: fixed;
  top: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 25mm;
  position: fixed;
  bottom: 0;
  margin-right: 25mm;
  margin-left: 25mm;
}
