@import 'palette.scss';

// Variables should be overwritten BEFORE importing bootstrap scss
$btn-padding-y: 12px;
$btn-padding-x: 30px;
$btn-border-radius: 24px;
$btn-font-weight: 800;

$form-text-color: $bajkomat_secondary;
$form-text-font-size: 18px;
$form-label-font-size: 14px;
$form-label-font-weight: bold;
$form-label-color: $bajkomat_secondary;
$input-padding-y: 8px;
$input-padding-x: 12px;
$input-border-color: $bajkomat_grey_100;
$input-border-width: 2px;
$input-border-radius: 5px;
$form-label-margin-bottom: 6px;

$primary: $bajkomat_primary;

@import '~bootstrap/scss/bootstrap';

// Classes should be overwritten AFTER importing bootstrap scss (to avoid !important)
.btn {
  padding: 13px 61px;

  &.fullWidth {
    width: 100%;
    padding: 13px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.shorter {
    padding: 10px 30px;
  }
}

.btn-primary {
  color: #fff !important;
  border-width: 2px;
}

.btn-text-primary {
  color: $bajkomat_primary;
  border-width: 2px;

  &:hover {
    color: $bajkomat_primary_light !important;
  }

  &:active {
    color: $bajkomat_primary_light !important;
  }

  &:focus-visible {
    color: $bajkomat_primary_light !important;
  }

  &:focus {
    color: $bajkomat_primary_light !important;
  }
}

.btn-outline-primary {
  border-width: 2px;

  &:hover,
  :active {
    color: #fff !important;
  }
}

.btn-outline-secondary {
  border-width: 2px;
  border-color: $bajkomat_secondary;
  color: $bajkomat_secondary;

  &:hover,
  :active {
    color: #fff !important;
    border-color: $bajkomat_secondary;
    background-color: $bajkomat_secondary;
  }
}

.btn-secondary {
  background-color: $bajkomat_secondary;

  &:hover {
    background-color: $bajkomat_secondary_light !important;
  }

  &:active {
    background-color: $bajkomat_secondary_lighter !important;
  }

  &:focus-visible {
    background-color: $bajkomat_secondary_lighter !important;
  }

  &:focus {
    background-color: $bajkomat_secondary_lighter !important;
  }

  &:disabled {
    background-color: $bajkomat_secondary !important;
    opacity: 0.5 !important;
  }
}

.btn-grey {
  background-color: $bajkomat_grey_100 !important;
  color: $bajkomat_secondary !important;

  &:hover {
    background-color: $bajkomat_grey_200 !important;
  }

  &:active {
    background-color: $bajkomat_grey_200 !important;
  }

  &:focus-visible {
    background-color: $bajkomat_grey_200 !important;
  }

  &:focus {
    background-color: $bajkomat_grey_200 !important;
  }

  &:disabled {
    background-color: $bajkomat_grey_100 !important;
    opacity: 0.5 !important;
  }
}

.btn-link {
  font-size: 18px;
  color: $bajkomat_primary;
  text-decoration: none;
  padding: 4px;
  font-weight: 800;
  font-size: 18px;
  border-width: 2px;
}

.slick-prev {
  left: -62px !important;
}

.slick-next {
  right: -48px !important;
}

.slick-prev:before {
  display: none;
}

.slick-next:before {
  display: none;
}

.modal-header {
  border-bottom: none;
}

.bg-token-amount-secondary {
  background-color: $bajkomat_secondary !important;
  color: $bajkomat_grey_100 !important;
  font-size: 12px;
  padding: 2px 4px;
  border: 1px solid $bajkomat_white;
}

.bg-token-amount-grey {
  background-color: #fdfdfd !important;
  color: $bajkomat_secondary !important;
  font-size: 12px;
  padding: 2px 4px;
}

.bg-story-cost-badge {
  background-color: $bajkomat_grey_100 !important;
  color: $bajkomat_secondary !important;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.bg-cost-badge {
  background-color: $bajkomat_secondary !important;
  color: $bajkomat_white !important;
  font-size: 14px;
  padding: 4px, 6px, 4px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.bg-cost-badge {
  background-color: $bajkomat_secondary !important;
  color: $bajkomat_white !important;
  font-size: 14px;
  padding: 4px, 6px, 4px;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.form-label {
  color: $bajkomat_black;
  font-weight: 400;
}
